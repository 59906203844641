import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  useToast,
  Text
} from '@chakra-ui/react'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { useNavigate } from 'react-router-dom'
import { signIn } from '../services/apiService'
import HeaderBack from '../components/HeaderBack'

const SignIn = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()

  const [account, setAccount] = useState('')
  const [password, setPassword] = useState('')
  const [auth] = useRecoilState(authState)

  const handleSignIn = async () => {
    try {
      const { data: res } = await signIn({
        account: account,
        password: password
      })
      if (res.statusCode === 200) {
        navigate('/app')
      }
    } catch (err: any) {
      toast({
        title: t('로그인 실패'),
        description: t(err.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  // ** Lifecycle
  useEffect(() => {
    // if (!auth.walletAddress) {
    //   navigate('/')
    // }
  }, [])

  return (
    <DefaultLayout showHamburgerMenu={false}>
      <Box>
        <HeaderBack title={t('로그인')} />
      </Box>

      <Box maxW="sm" mx="auto" mt="8">
        <Stack spacing="4">
          <FormControl id="wallet-address">
            <FormLabel color="white">{t('아이디')}</FormLabel>
            <Input
              type="text"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
              bg={'white'}
            />
          </FormControl>

          <FormControl id="password">
            <FormLabel color="white">{t('비밀번호')}</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              bg={'white'}
            />
          </FormControl>

          <Button
            mt={5}
            type="submit"
            bg="#ff2d89"
            color="#ffffff"
            size="lg"
            _hover={{ bg: 'pink.400' }}
            width="full"
            onClick={handleSignIn}
          >
            {t('로그인')}
          </Button>
        </Stack>
      </Box>
    </DefaultLayout>
  )
}

export default SignIn
