import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  Text
} from '@chakra-ui/react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import { changeUsername } from '../services/apiService'
import HeaderBack from '../components/HeaderBack'
import { useUpdateAuthState } from '../utils/authUtils'

const ChangeUsername: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const toast = useToast()
  const { t } = useTranslation()
  const [initialValues, setInitialValues] = useState({ newUsername: '' })
  const updateAuthState = useUpdateAuthState()

  useEffect(() => {
    if (auth?.username) {
      setInitialValues({ newUsername: auth.username })
    }
  }, [auth])

  // Validation schema using Yup
  const validationSchema = Yup.object({
    newUsername: Yup.string()
      .min(3, t('사용자명은 3글자 이상입니다.'))
      .required(t('사용자명은 필수항목입니다.'))
  })

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      // 비동기 작업 또는 API 호출이 필요할 경우 이곳에서 처리
      const { data: res } = await changeUsername({
        username: values.newUsername
      })
      if (res.statusCode === 200) {
        toast({
          title: t('사용자명 변경'),
          description: t('사용자명이 변경되었습니다.'),
          status: 'success',
          duration: 1500,
          isClosable: true
        })
        updateAuthState()
      }
    } catch (err: any) {
      toast({
        title: t('Fail'),
        description: t(err.response?.data?.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box mb={5}>
          <HeaderBack title={t('사용자명 변경')} />
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <VStack spacing={4}>
                <FormControl id="newUsername">
                  <FormLabel color="white">{t('사용자명')}</FormLabel>
                  <Field name="newUsername">
                    {({ field }: { field: any }) => (
                      <Input type="text" {...field} bg={'white'} />
                    )}
                  </Field>
                  <ErrorMessage name="newUsername">
                    {(msg) => <Text color="red.500">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>

                <Button
                  mt={5}
                  type="submit"
                  bg="#ff2d89"
                  color="#ffffff"
                  size="lg"
                  _hover={{ bg: 'pink.400' }}
                  width="full"
                >
                  {t('사용자명 변경')}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </DefaultLayout>
    </>
  )
}

export default ChangeUsername
