import { Box, IconButton } from '@chakra-ui/react'
import {
  FaHome,
  // FaHandPaper,
  // FaChartBar,
  FaUser,
  FaCalculator,
  FaInfo,
  FaAddressBook,
  FaNetworkWired,
  FaMoneyBill,
  FaMoneyCheck,
  FaCoins
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const BottomNav = () => {
  // ** Hooks
  const navigate = useNavigate()

  return (
    <>
      {/* 하단 네비게이션 */}
      <Box
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        bg="gray.800" // 배경색 어둡게 설정
        boxShadow="0px -2px 5px rgba(0,0,0,0.3)"
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        padding="10px 0"
      >
        <IconButton
          icon={<FaHome />}
          aria-label="홈"
          variant="ghost"
          color="white" // 아이콘 색상 흰색
          _hover={{ bg: 'gray.700' }} // hover 상태 색상
          onClick={() => navigate('/app')}
        />
        <IconButton
          icon={<FaCoins />}
          aria-label="입금"
          variant="ghost"
          color="white" // 아이콘 색상 흰색
          _hover={{ bg: 'gray.700' }} // hover 상태 색상
          onClick={() => navigate('/deposit')}
        />
        <IconButton
          icon={<FaNetworkWired />}
          aria-label="팀"
          variant="ghost"
          color="white" // 아이콘 색상 흰색
          _hover={{ bg: 'gray.700' }} // hover 상태 색상
          onClick={() => navigate('/team')}
        />

        <IconButton
          icon={<FaUser />}
          aria-label="나의NFT"
          variant="ghost"
          color="white" // 아이콘 색상 흰색
          _hover={{ bg: 'gray.700' }} // hover 상태 색상
          onClick={() => navigate('/myNFT')}
        />
      </Box>
    </>
  )
}

export default BottomNav
