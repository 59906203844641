import { Container } from '@chakra-ui/react'
import AppBar from './AppBar'
import Wave from 'react-wavify'
import { useEffect } from 'react'
import Web3 from 'web3'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { authState } from '../../atoms/auth'
import { useUpdateAuthState } from '../../utils/authUtils'

interface DefaultLayoutProps {
  children: React.ReactNode
  showHamburgerMenu?: boolean
}

const DefaultLayout = ({
  children,
  showHamburgerMenu = true
}: DefaultLayoutProps) => {
  const navigate = useNavigate()
  const updateAuthState = useUpdateAuthState()

  // ** Lifecycle
  useEffect(() => {
    updateAuthState()
  }, [])

  return (
    <>
      <AppBar showHamburgerMenu={showHamburgerMenu} />
      <Container maxW="1200px" mt={4}>
        {children}
      </Container>
    </>
  )
}

export default DefaultLayout
