import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactFlow, {
  Controls,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  Node,
  Edge,
  Connection,
  Handle,
  Position
} from 'reactflow'
import 'reactflow/dist/style.css'
import dagre from 'dagre'
import {
  Box,
  Text,
  Button,
  VStack,
  Heading,
  Tag,
  Divider
} from '@chakra-ui/react'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { getTeamChartSup, getTeamChartRef } from '../services/apiService'
import { getBezierPath } from 'reactflow'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import CheckAuth from '../components/layouts/CheckAuth'
import BigNumber from 'bignumber.js'
import { authState } from '../atoms/auth'
import { useRecoilState } from 'recoil'
import HeaderBack from '../components/HeaderBack'
import BottomNav from '../components/BottomNav'

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd
}: any) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <text>
        <textPath
          href={`#${id}`}
          style={{ fontSize: 12 }}
          startOffset="50%"
          textAnchor="middle"
        >
          {data.text}
        </textPath>
      </text>
    </>
  )
}

const CustomNode = ({ data, isConnectable }: any) => {
  const { t } = useTranslation()
  let color = '#ffffff'

  const userLevel = data.userLevel

  if (userLevel === 0) {
    color = '#ffffff' // White (Level 0)
  } else if (userLevel === 1) {
    color = '#FFB3BA' // Pastel Red (Level 1)
  } else if (userLevel === 2) {
    color = '#FFDFBA' // Pastel Orange (Level 2)
  } else if (userLevel === 3) {
    color = '#FFFFBA' // Pastel Yellow (Level 3)
  } else if (userLevel === 4) {
    color = '#BAFFC9' // Pastel Green (Level 4)
  } else if (userLevel === 5) {
    color = '#BAE1FF' // Pastel Blue (Level 5)
  } else if (userLevel === 6) {
    color = '#B39EB5' // Pastel Purple (Level 6)
  } else if (userLevel === 7) {
    color = '#FFB347' // Pastel Orange (Level 7)
  } else if (userLevel === 8) {
    color = '#AEC6CF' // Pastel Blue (Level 8)
  } else if (userLevel === 9) {
    color = '#CFCFC4' // Pastel Gray (Level 9)
  }

  return (
    <div
      style={{
        border: '1px black solid',
        padding: '10px',
        borderRadius: '10px',
        background: color
      }}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />

      <Box>
        <Box mb={1} textAlign={'center'}>
          <Text as="b">{t('아이디')}</Text>
          <Box>
            <Tag variant={'outlined'}>{data.account}</Tag>
          </Box>
          <Text as="b">{t('사용자명')}</Text>
          <Box>
            <Tag variant={'outlined'}>{data.username}</Tag>
          </Box>
        </Box>

        <Box my={2}>
          <Divider />
        </Box>

        <Box mb={1} textAlign={'center'}>
          <Text as="b">{t('레퍼럴 코드')}</Text>
          <Box>
            <Tag>{data.code}</Tag>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

const Team: React.FC = () => {
  const { t } = useTranslation()
  const { setCenter, fitView } = useReactFlow()
  const [searchUserAccount, setSearchUserAccount] = useState<string>('')
  const [selectedOption, setSelectedOption] = useState<'ref' | 'sup'>('ref')
  const [auth, setAuth] = useRecoilState(authState)

  // Define dagre graph
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  // Define node size
  const nodeWidth = 400
  const nodeHeight = 450

  // Auto layout
  const getLayoutedElements = (
    nodes: Node[],
    edges: Edge[],
    direction = 'TB'
  ) => {
    const isHorizontal = direction === 'LR'
    dagreGraph.setGraph({ rankdir: direction })

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
    })

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target)
    })

    dagre.layout(dagreGraph)

    nodes.forEach((node: any) => {
      const nodeWithPosition = dagreGraph.node(node.id)
      node.targetPosition = isHorizontal ? 'left' : 'top'
      node.sourcePosition = isHorizontal ? 'right' : 'bottom'
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2
      }
    })

    return { nodes, edges }
  }

  // Node and Edge Hooks
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  )

  // Focus node
  const focusNode = (account: string) => {
    const node = nodes.find(
      (node) => node.data.account.toLowerCase() === account.toLowerCase()
    )
    if (node) {
      const x = node.position.x + nodeWidth / 2
      const y = node.position.y + nodeHeight * 3
      const zoom = 0.7
      setCenter(x, y, { zoom, duration: 1000 })
    }
  }

  // Apply layout change
  const onLayout = useCallback(
    (direction: 'TB' | 'LR') => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction)
      setNodes([...layoutedNodes])
      setEdges([...layoutedEdges])
    },
    [nodes, edges]
  )

  const edgeTypes: any = {
    custom: CustomEdge
  }
  const nodeTypes = useMemo(() => ({ custom: CustomNode }), [])

  const initData = async () => {
    try {
      let res
      if (selectedOption === 'ref') {
        const { data: resRef } = await getTeamChartRef()
        res = resRef
      } else {
        const { data: resSup } = await getTeamChartSup()
        res = resSup
      }

      if (res.statusCode === 200) {
        const n = res.data.nodes
        const e = res.data.edges

        if (n.length === 0 && e.length === 0) {
          // toast(t('No search results'), { icon: '😓' })
        }

        // Auto layout
        const { nodes: layoutedNodes, edges: layoutedEdges } =
          getLayoutedElements(n, e)

        // Set nodes and edges
        setNodes(layoutedNodes)
        setEdges(layoutedEdges)

        // Fit view
        fitView({
          padding: 1, // Adjust padding as necessary (e.g., 0.1 for 10% padding)
          duration: 800 // Smooth transition duration (in milliseconds)
        })
      }
    } catch (err) {
      // pass
    }
  }

  useEffect(() => {
    initData()
  }, [selectedOption])

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box mb={5}>
          <HeaderBack title={t('레퍼럴')} />
        </Box>

        <VStack spacing={4} mb={8}>
          <Box
            width="100%"
            height="450px"
            borderRadius="4px"
            background="#000000"
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodesDraggable={false}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              fitView
              minZoom={0.001}
            >
              <Controls />
            </ReactFlow>
          </Box>
        </VStack>

        <BottomNav />
      </DefaultLayout>
    </>
  )
}

export default Team
