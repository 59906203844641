import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from './locales/en/translation.json'
import translationKO from './locales/ko/translation.json'
// import translationJA from './locales/ja/translation.json'
import translationZH from './locales/zh/translation.json'
// import translationTH from './locales/th/translation.json'
// import translationVI from './locales/vi/translation.json'
import translationID from './locales/id/translation.json'

const resources = {
  en: {
    translation: translationEN
  },
  ko: {
    translation: translationKO
  },
  id: {
    translation: translationID
  },
  // ja: {
  //   translation: translationJA
  // },
  zh: {
    translation: translationZH
  }
  // th: {
  //   translation: translationTH
  // },
  // vi: {
  //   translation: translationVI
  // }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'ko', // default language
  fallbackLng: 'ko', // fallback language
  interpolation: {
    escapeValue: false
  }
})

export default i18n
