import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import {
  Text,
  Button,
  Flex,
  Box,
  useToast,
  Image,
  Badge,
  VStack
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useNavigate, useLocation } from 'react-router-dom'
import { inviteState } from '../atoms/invite'

const Home = () => {
  // ** Hooks
  const { t } = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  // ** State
  const [_, setInvite] = useRecoilState(inviteState)

  // ** Handler
  const handleClickSignIn = async () => {
    navigate('/signIn')
  }

  const handleClickSignUp = async () => {
    navigate('/signUp')
  }

  // ** Lifecycle
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const inviteCode = params.get('inviteCode')

    if (inviteCode) {
      setInvite(inviteCode)
    }
  }, [location.search, setInvite])

  return (
    <DefaultLayout showHamburgerMenu={false}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
        bg="#000000"
        color="white"
      >
        {/* 로고 영역 */}
        <Image
          src="/images/logo/symbol.png"
          sx={{ width: '60px', height: 'auto' }}
        />
        <Box mt={3}>
          <Box textAlign={'center'}>
            <Text fontSize={'xl'} fontWeight={'bold'}>
              {t('파라다이스 플랫폼')} <Badge colorScheme="green">NFT</Badge>
            </Text>
          </Box>
        </Box>

        {/* 버튼 그룹 */}
        <VStack spacing={4} mt={5}>
          <Button
            width="200px"
            bg="#f03e8f"
            color="white"
            _hover={{ bg: 'pink.400' }}
            onClick={handleClickSignIn}
          >
            {t('로그인')}
          </Button>
          <Button
            width="200px"
            bg="white"
            color="black"
            _hover={{ bg: 'gray.200' }}
            onClick={handleClickSignUp}
          >
            {t('회원가입')}
          </Button>
        </VStack>
      </Box>
    </DefaultLayout>
  )
}

export default Home
