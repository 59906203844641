import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  Text,
  Stack
} from '@chakra-ui/react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import { changePassword } from '../services/apiService'
import HeaderBack from '../components/HeaderBack'

const ChangePassword: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const toast = useToast()
  const { t } = useTranslation()

  // Validation schema using Yup
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required('현재 비밀번호는 필수항목입니다.'),
    newPassword: Yup.string()
      .min(8, '새로운 비밀번호는 8글자 이상입니다.')
      .required('새로운 비밀번호는 필수항목입니다.'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], '새로운 비밀번호와 일치해야합니다.')
      .required('새로운 비밀번호 확인은 필수항목입니다.')
  })

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const { data: res } = await changePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword
      })
      if (res.statusCode === 200) {
        toast({
          title: '비밀번호 변경',
          description: '비밀번호가 변경되었습니다.',
          status: 'success',
          duration: 1500,
          isClosable: true
        })

        // 폼초기화
        values.currentPassword = ''
        values.newPassword = ''
        values.confirmNewPassword = ''
      }
    } catch (err: any) {
      toast({
        title: t('Fail'),
        description: t(err.response?.data?.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  return (
    <>
      <CheckAuth />

      <DefaultLayout>
        <Box mb={5}>
          <HeaderBack title={t('비밀번호 변경')} />
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <VStack spacing={4}>
                <FormControl id="currentPassword">
                  <FormLabel color="white">{t('현재 비밀번호')}</FormLabel>
                  <Field name="currentPassword">
                    {({ field }: { field: any }) => (
                      <Input type="password" {...field} bg={'white'} />
                    )}
                  </Field>
                  <ErrorMessage name="currentPassword">
                    {(msg) => <Text color="red.500">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>
                <FormControl id="newPassword">
                  <FormLabel color="white">{t('새로운 비밀번호')}</FormLabel>
                  <Field name="newPassword">
                    {({ field }: { field: any }) => (
                      <Input type="password" {...field} bg={'white'} />
                    )}
                  </Field>
                  <ErrorMessage name="newPassword">
                    {(msg) => <Text color="red.500">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>
                <FormControl id="confirmNewPassword">
                  <FormLabel color="white">
                    {t('새로운 비밀번호 확인')}
                  </FormLabel>
                  <Field name="confirmNewPassword">
                    {({ field }: { field: any }) => (
                      <Input type="password" {...field} bg={'white'} />
                    )}
                  </Field>
                  <ErrorMessage name="confirmNewPassword">
                    {(msg) => <Text color="red.500">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>

                <Button
                  mt={5}
                  type="submit"
                  bg="#ff2d89"
                  color="#ffffff"
                  size="lg"
                  _hover={{ bg: 'pink.400' }}
                  width="full"
                >
                  {t('비밀번호 변경')}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </DefaultLayout>
    </>
  )
}

export default ChangePassword
