// src/utils/authUtils.ts

import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { getUser } from '../services/apiService'

export const useUpdateAuthState = () => {
  const [auth, setAuth] = useRecoilState(authState)

  const updateAuthState = async () => {
    try {
      const response = await getUser()
      if (response?.data?.data) {
        // NOTE setAuth수정
        const {
          id,
          account,
          username,
          pointBalance,
          code,
          nftList,
          walletAddress
        } = response.data.data
        setAuth({
          id: id || null,
          account: account || null,
          username: username || null,
          pointBalance: pointBalance || null,
          code: code || null,
          nftList: nftList || [],
          walletAddress: walletAddress || null
        })
      }
    } catch (error) {
      console.error('Failed to update auth state', error)
    }
  }

  return updateAuthState
}
