import { useRecoilState } from 'recoil'
import { getUser, getWVPUSDT } from '../../services/apiService'
import { authState } from '../../atoms/auth'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { rateState } from '../../atoms/rate'

const CheckAuth = () => {
  const [auth, setAuth] = useRecoilState(authState)
  const [rate, setRate] = useRecoilState(rateState)
  const navigate = useNavigate()

  // 방어 로직
  const initData = async () => {
    try {
      const { data: res } = await getUser()
      if (res.statusCode === 200) {
        const user = res.data

        // DEBUG
        console.log('user => ', user)

        // NOTE setAuth수정
        setAuth({
          id: user.id,
          account: user.account,
          username: user.username,
          pointBalance: user.pointBalance,
          code: user.code,
          nftList: user.nftList,
          walletAddress: user.walletAddress
        })
        // 추가적인 사용자 데이터가 있다면 여기에 반영하세요
      }
    } catch (err: any) {
      navigate('/')
    }
  }

  // ** Lifecycle
  useEffect(() => {
    initData()

    // 컴포넌트가 언마운트될 때 interval 정리
    return () => {
      //
    }
  }, [])

  return <></>
}

export default CheckAuth
