import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Text
} from '@chakra-ui/react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { inviteState } from '../atoms/invite'
import { useNavigate } from 'react-router-dom'
import { signUp } from '../services/apiService'
import HeaderBack from '../components/HeaderBack'

const SignUp: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const [invite] = useRecoilState(inviteState)
  const toast = useToast()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false) // Add loading state

  // Validation schema using Yup
  const validationSchema = Yup.object({
    account: Yup.string()
      .min(3, t('아이디는 3글자 이상입니다.'))
      .required(t('아이디는 필수항목입니다.')),
    username: Yup.string()
      .min(3, t('사용자명은 3글자 이상입니다.'))
      .required(t('사용자명은 필수항목입니다.')),
    password: Yup.string()
      .min(8, t('비밀번호는 8글자 이상입니다.'))
      .required(t('비밀번호는 필수항목입니다.')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('비밀번호와 일치해야합니다.'))
      .required(t('비밀번호 확인은 필수항목입니다.')),
    referralCode: Yup.string()
      .length(8, t('레퍼럴 코드는 8글자 입니다.'))
      .required(t('레퍼럴코드는 필수항목입니다.'))
  })

  const initialValues = {
    account: '',
    username: '',
    password: '',
    confirmPassword: '',
    referralCode: invite || '' // Pre-fill with invite code if available
  }

  const handleSubmit = async (values: typeof initialValues) => {
    setLoading(true) // Start loading

    try {
      const { data: res } = await signUp({
        account: values.account,
        username: values.username,
        password: values.password,
        referralCode: values.referralCode
      })
      if (res.statusCode === 200) {
        toast({
          title: t('회원가입 완료'),
          description: t('파라다이스 플랫폼에 오신 것을 환영합니다.'),
          status: 'success',
          duration: 1500,
          isClosable: true
        })

        navigate('/signIn')
      }
    } catch (err: any) {
      toast({
        title: t('실패'),
        description: t(err.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    } finally {
      setLoading(false) // Stop loading
    }
  }

  // ** Lifecycle
  useEffect(() => {
    // Add any side effects or checks if necessary
  }, [])

  return (
    <DefaultLayout showHamburgerMenu={false}>
      <Box mb={5}>
        <HeaderBack title={t('회원가입')} />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <VStack spacing={4}>
              <FormControl id="walletAddress" isRequired>
                <FormLabel color="white">{t('아이디')}</FormLabel>
                <Field name="account">
                  {({ field }: { field: any }) => (
                    <Input type="text" {...field} bg={'white'} />
                  )}
                </Field>
              </FormControl>
              <FormControl id="username">
                <FormLabel color="white">{t('사용자명')}</FormLabel>
                <Field name="username">
                  {({ field }: { field: any }) => (
                    <Input type="text" {...field} bg={'white'} />
                  )}
                </Field>
                <ErrorMessage name="username">
                  {(msg) => <Text color="red.500">{msg}</Text>}
                </ErrorMessage>
              </FormControl>
              <FormControl id="password">
                <FormLabel color="white">{t('비밀번호')}</FormLabel>
                <Field name="password">
                  {({ field }: { field: any }) => (
                    <Input type="password" {...field} bg={'white'} />
                  )}
                </Field>
                <ErrorMessage name="password">
                  {(msg) => <Text color="red.500">{msg}</Text>}
                </ErrorMessage>
              </FormControl>
              <FormControl id="confirmPassword">
                <FormLabel color="white">{t('비밀번호 확인')}</FormLabel>
                <Field name="confirmPassword">
                  {({ field }: { field: any }) => (
                    <Input type="password" {...field} bg={'white'} />
                  )}
                </Field>
                <ErrorMessage name="confirmPassword">
                  {(msg) => <Text color="red.500">{msg}</Text>}
                </ErrorMessage>
              </FormControl>
              <FormControl id="referralCode">
                <FormLabel color="white">{t('레퍼럴 코드')}</FormLabel>
                <Field name="referralCode">
                  {({ field }: { field: any }) => (
                    <Input
                      type="text"
                      {...field}
                      readOnly={!!invite} // Make read-only if invite code is present
                      sx={invite ? { bg: 'gray.100' } : {}}
                      bg={'white'}
                    />
                  )}
                </Field>
                <ErrorMessage name="referralCode">
                  {(msg) => <Text color="red.500">{msg}</Text>}
                </ErrorMessage>
              </FormControl>

              <Button
                mt={5}
                type="submit"
                bg="#ff2d89"
                color="#ffffff"
                size="lg"
                _hover={{ bg: 'pink.400' }}
                width="full"
                isLoading={loading} // Add loading state to the button
              >
                {t('회원가입')}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </DefaultLayout>
  )
}

export default SignUp
