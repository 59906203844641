import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  useClipboard,
  useToast
} from '@chakra-ui/react'
import CheckAuth from '../components/layouts/CheckAuth'
import DefaultLayout from '../components/layouts/DefaultLayout'
import HeaderBack from '../components/HeaderBack'
import BottomNav from '../components/BottomNav'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { useTranslation } from 'react-i18next'

const Deposit: React.FC = () => {
  // ** Hooks
  const [auth] = useRecoilState(authState)

  // 문의 내용 정의
  const inquiryContent = `
    문의 내용
    보낸이 은행명 : 파라다이스 은행
    보낸이 계좌주 : 홍길동
    보낸이 계좌번호 : 123-12345-12345
    보낸 금액 : 980,000 원
    요청 포인트 : 700 p
  `

  // Clipboard hook for copying the inquiry content
  const { hasCopied, onCopy } = useClipboard(inquiryContent)

  // Toast hook for user feedback
  const toast = useToast()
  const { t } = useTranslation()

  return (
    <>
      <CheckAuth />

      <DefaultLayout>
        <Box mb={5}>
          <HeaderBack title={t('포인트 충전')} />
        </Box>

        <Box textAlign={'center'}>
          <Box>
            <Text color="white">{t('은행명')}</Text>
            <Text color="white" fontSize={'xl'} fontWeight="bold">
              광주은행
            </Text>
          </Box>

          <Box mt={5}>
            <Text color="white">{t('계좌주')}</Text>
            <Text color="white" fontSize={'xl'} fontWeight="bold">
              (주) 엔에스스튜디오
            </Text>
          </Box>

          <Box mt={5}>
            <Text color="white">{t('계좌번호')}</Text>
            <Text color="white" fontSize={'xl'} fontWeight="bold">
              1107-021-117084
            </Text>
          </Box>

          <Box mt={5}>
            <Text color="yellow" fontWeight="bold">
              {t('계좌이체하신 후 아래 버튼을 눌러서 문의해주세요.')}
            </Text>
          </Box>

          <Box
            mt={5}
            textAlign="left"
            px={5}
            py={3}
            bg="gray.800"
            borderRadius="md"
          >
            <Text color="white" fontWeight="bold" mb={2}>
              {t('문의 내용')}
            </Text>
            <Text color="white" fontSize="sm">
              {t('보낸이 은행명')} :
            </Text>
            <Text color="white" fontSize="sm">
              {t('보낸이 계좌주')} :
            </Text>
            <Text color="white" fontSize="sm">
              {t('보낸이 계좌번호')} :
            </Text>
            <Text color="white" fontSize="sm">
              {t('보낸 금액')} :
            </Text>
            <Text color="white" fontSize="sm">
              {t('요청 포인트')} :
            </Text>
            <Text color="white" fontSize="sm">
              {t('아이디')} :
            </Text>

            <Button
              bg="#ff2d89"
              color="#ffffff"
              _hover={{ bg: 'pink.400' }}
              width="full"
              mt={3}
              onClick={() => {
                onCopy()
                toast({
                  title: t('문의 내용이 복사되었습니다.'),
                  status: 'success',
                  duration: 2000,
                  isClosable: true
                })
              }}
            >
              {hasCopied ? t('복사 완료!') : t('문의 내용 복사')}
            </Button>
          </Box>

          <Box mt={5}>
            <Button
              onClick={() => window.open('https://t.me/Lucas_1404', '_blank')}
              colorScheme="blue"
              size="lg"
              width="80%"
            >
              {t('포인트 충전 의뢰 (텔레그램)')}
            </Button>
          </Box>

          <Box pb={100}></Box>

          <Box></Box>
        </Box>

        <BottomNav />
      </DefaultLayout>
    </>
  )
}

export default Deposit
