import { Box, Flex, Image, Text } from '@chakra-ui/react'
import CheckAuth from '../components/layouts/CheckAuth'
import DefaultLayout from '../components/layouts/DefaultLayout'
import HeaderBack from '../components/HeaderBack'
import BottomNav from '../components/BottomNav'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { useTranslation } from 'react-i18next'

const NFTCard = ({ grade }: any) => {
  // ** Hooks
  const [auth] = useRecoilState(authState)
  const { t } = useTranslation()

  return (
    <>
      <Flex
        alignItems="center"
        gap={4}
        p={4}
        border="1px solid #fff"
        borderRadius="md"
        bg="gray.800"
      >
        <Box>
          {grade === 'VIP' && (
            <Image
              src="/images/invitation.png"
              alt="Invitation"
              sx={{ width: '60px', height: 'auto', borderRadius: 'md' }}
            />
          )}
          {grade === 'GOLD' && (
            <Image
              src="/images/gold-bars.png"
              alt="Invitation"
              sx={{ width: '60px', height: 'auto', borderRadius: 'md' }}
            />
          )}
          {grade === 'SPECIAL' && (
            <Image
              src="/images/badge.png"
              alt="Invitation"
              sx={{ width: '60px', height: 'auto', borderRadius: 'md' }}
            />
          )}
        </Box>
        <Box>
          <Text
            fontSize="lg"
            fontWeight="bold"
            color="white"
            textAlign="left"
            mb={1}
          >
            {grade} NFT
          </Text>
          <Text
            fontSize="md"
            fontWeight="medium"
            color="gray.300"
            textAlign="left"
          >
            {t('소유자')}: {auth?.username}
          </Text>
        </Box>
      </Flex>
    </>
  )
}

const Hello: React.FC = () => {
  // ** Hooks
  const [auth] = useRecoilState(authState)
  const { t } = useTranslation()

  return (
    <>
      <CheckAuth />

      <DefaultLayout>
        <Box mb={5}>
          <HeaderBack title={t('나의 NFT')} />
        </Box>

        {auth?.nftList.length === 0 && (
          <>
            <Text mt={20} color="white" textAlign={'center'}>
              {t('보유하고 있는 NFT가 없습니다.')}
            </Text>
          </>
        )}

        {auth?.nftList.map((nft: any, idx: number) => {
          return (
            <>
              <Box mb={3}>
                <NFTCard grade={nft.grade} />
              </Box>
            </>
          )
        })}

        <BottomNav />
      </DefaultLayout>
    </>
  )
}

export default Hello
