// src/atoms/auth.ts
import { atom } from 'recoil'

interface AuthState {
  id: number | null
  account: string | null
  username: string | null
  pointBalance: string | null
  code: string | null
  nftList: any[]
  walletAddress: string | null
}

export const authState = atom<AuthState>({
  key: 'authState',
  default: {
    id: null,
    account: null,
    username: null,
    pointBalance: null,
    code: null,
    nftList: [],
    walletAddress: null
  }
})
