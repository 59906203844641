// ** React
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

// ** Global
import './index.css'
import './wave.css'
import './i18n'

// ** Third
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { RecoilRoot } from 'recoil'
import { ReactFlowProvider } from 'reactflow' // ReactFlowProvider를 추가

// ** Page
import Sample from './pages/Sample'
import Home from './pages/Home'
import SignUp from './pages/SignUp'
import SignIn from './pages/SignIn'
import App from './pages/App'
// import Withdrawal from './pages/Withdrawal'
import Deposit from './pages/Deposit'
import Team from './pages/Team'
import History from './pages/History'
import Marketing from './pages/Marketing'
import ChangePassword from './pages/ChangePassword'
import Notice from './pages/Notice'
import NoticeList from './pages/Notice\bList'
import ChangeUsername from './pages/ChangeUsername'
// import Hotel from './pages/Hotel'
import HotelDetail from './pages/HotelDetail'
import Shop from './pages/Shop'
import ShopDetail from './pages/ShopDetail'
import Lucky from './pages/Lucky'
import MyNFT from './pages/Hello'
import Hello from './pages/Hello'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/signUp',
    element: <SignUp />
  },
  {
    path: '/signIn',
    element: <SignIn />
  },
  {
    path: '/app',
    element: <App />
  },
  {
    path: '/deposit',
    element: <Deposit />
  },
  // {
  //   path: '/withdrawal',
  //   element: <Withdrawal />
  // },
  {
    path: '/team',
    element: <Team />
  },
  {
    path: '/history',
    element: <History />
  },
  {
    path: '/marketing',
    element: <Marketing />
  },
  {
    path: '/password',
    element: <ChangePassword />
  },
  {
    path: '/username',
    element: <ChangeUsername />
  },
  {
    path: '/notice', // 동적 경로 설정
    element: <NoticeList />
  },
  {
    path: '/notice/:id', // 동적 경로 설정
    element: <Notice />
  },
  {
    path: '/hotel/:id', // 동적 경로 설정
    element: <HotelDetail />
  },
  {
    path: '/shop',
    element: <Shop />
  },
  {
    path: '/shop/:id', // 동적 경로 설정
    element: <ShopDetail />
  },
  {
    path: '/lucky',
    element: <Lucky />
  },
  {
    path: '/sample',
    element: <Sample />
  },
  {
    path: '/myNFT',
    element: <Hello />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <ChakraProvider>
    <RecoilRoot>
      <ReactFlowProvider>
        <RouterProvider router={router} />
      </ReactFlowProvider>
    </RecoilRoot>
  </ChakraProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
