import { useEffect, useState } from 'react'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useTranslation } from 'react-i18next'
import {
  useToast,
  Box,
  Text,
  Button,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Divider
} from '@chakra-ui/react'
import BottomNav from '../components/BottomNav'
import { buyNFT, currentNFTCondition } from '../services/apiService'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import BigNumber from 'bignumber.js'

const NFTStore = () => {
  // ** Hooks
  const [auth] = useRecoilState(authState)
  const { t } = useTranslation()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedNFT, setSelectedNFT] = useState<string | null>(null)

  const [nftData, setNftData] = useState([
    {
      type: 'VIP',
      price: '200,000p',
      quantity: 0,
      imageUrl: '/images/invitation.png'
    },
    {
      type: 'GOLD',
      price: '100,000p',
      quantity: 0,
      imageUrl: '/images/gold-bars.png'
    },
    {
      type: 'SPECIAL',
      price: '2,000p',
      quantity: 0,
      imageUrl: '/images/badge.png'
    }
  ])

  const handlePurchase = (type: string) => {
    setSelectedNFT(type)
    onOpen()
  }

  const confirmPurchase = async () => {
    try {
      const { data: res } = await buyNFT({
        grade: selectedNFT
      })
      if (res.statusCode === 200) {
        toast({
          title: t(`${selectedNFT} NFT ${t('구매')}`),
          description: t('NFT 구매가 완료되었습니다.'),
          status: 'success',
          duration: 3000,
          isClosable: true
        })

        initData()
      }
    } catch (err: any) {
      toast({
        title: t('NFT 구매'),
        description: t(err.response.data.message),
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      onClose()
    }
  }

  const initData = async () => {
    const { data: res } = await currentNFTCondition()
    if (res.statusCode === 200) {
      setNftData(res.data)
    }
  }

  useEffect(() => {
    initData()
  }, [])

  return (
    <>
      <DefaultLayout>
        <Box maxW="lg" mx="auto">
          <Text fontSize="2xl" fontWeight="bold" mb={4} color="white">
            {t('NFT 상점')}
          </Text>

          <Box mb={3}>
            <Text color="white">
              {t('나의 포인트')}:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {auth?.pointBalance
                  ? new BigNumber(auth?.pointBalance).toFormat(2)
                  : '0.00'}{' '}
                P
              </span>
            </Text>
          </Box>

          <Box gap={6}>
            {nftData.map((nft) => (
              <Box
                key={nft.type}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                sx={{ borderColor: '#3c3c3c' }}
                p={4}
                mb={3}
              >
                <Box>
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    color="white"
                    textAlign={'center'}
                  >
                    {t(`${nft.type} NFT`)}
                  </Text>

                  <Divider borderColor={'#333333'} my={1} />

                  <Flex
                    gap={3}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        src={nft.imageUrl}
                        sx={{ width: '60px', height: 'auto' }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                    >
                      <Text fontSize="md" color="white">
                        {t('가격')}: {nft.price}
                      </Text>
                      <Text fontSize="md" color="white">
                        {t('남은 수량')} : {nft.quantity}
                      </Text>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        bg="#ff2d89"
                        color="#ffffff"
                        _hover={{ bg: 'pink.400' }}
                        width="full"
                        onClick={() => handlePurchase(nft.type)}
                        isDisabled={nft.quantity === 0}
                      >
                        {t('구매하기')}
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <BottomNav />

        {/* 구매 확인 모달 */}
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('구매 확인')}</ModalHeader>
            <ModalBody>
              <Text>{t(`${selectedNFT} NFT를 구매하시겠습니까?`)}</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                bg="#ff2d89"
                color="#ffffff"
                _hover={{ bg: 'pink.400' }}
                mr={3}
                onClick={confirmPurchase}
              >
                {t('확인')}
              </Button>
              <Button variant="ghost" onClick={onClose}>
                {t('취소')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </DefaultLayout>
    </>
  )
}

export default NFTStore
