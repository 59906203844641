import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import {
  Button,
  Grid,
  Box,
  Text,
  Heading,
  useToast,
  useClipboard,
  Input,
  Spinner,
  Flex
} from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import QRCode from 'react-qr-code'
import HeaderBack from '../components/HeaderBack'
import BottomNav from '../components/BottomNav'

const Marketing = () => {
  const { t } = useTranslation()
  const [auth] = useRecoilState(authState)
  const toast = useToast()

  const inviteUrl = `https://paradise-platform.com/?inviteCode=${auth.code}`

  // Clipboard hook for copying the invite URL
  const { hasCopied, onCopy } = useClipboard(inviteUrl)

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box textAlign="center">
          <Box mb={5}>
            <HeaderBack title={t('친구 초대')} />
          </Box>

          {/* QR Code */}
          <Box mb={6}>
            <Flex justifyContent="center">
              <QRCode value={inviteUrl} size={200} />
            </Flex>
          </Box>

          {/* Invite URL */}
          <Box mb={6}>
            <Input
              value={inviteUrl}
              isReadOnly
              size="lg"
              textAlign="center"
              bg="white"
            />
          </Box>

          {/* Copy Button */}
          <Button
            onClick={onCopy}
            colorScheme="teal"
            mt={1}
            type="submit"
            bg="#ff2d89"
            color="#ffffff"
            _hover={{ bg: 'pink.400' }}
            width="full"
          >
            {hasCopied ? t('복사 완료!') : t('링크 복사')}
          </Button>
        </Box>

        <BottomNav />
      </DefaultLayout>
    </>
  )
}

export default Marketing
