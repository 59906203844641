import { Box, IconButton, Flex } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

const HeaderBack = ({ title }: any) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <Flex justify="space-between" align="center" bg="black">
      <IconButton
        onClick={handleGoBack}
        icon={<ArrowBackIcon color="#ffffff" boxSize="6" />} // 화살표 두껍게
        variant="ghost" // 투명 배경
        aria-label="뒤로가기"
        _hover={{ bg: 'gray.700' }} // 호버 시 배경색 설정
        _focus={{ boxShadow: 'none' }} // 포커스 스타일 제거
      />
      <Box
        fontWeight={'bold'}
        flex="1"
        textAlign="center"
        fontSize="lg"
        color="white"
      >
        {title}
      </Box>
      <Box w="40px"></Box> {/* 오른쪽 공백 맞춤용 */}
    </Flex>
  )
}

export default HeaderBack
